header {
	position: relative;
}

.logo {
	display: flex;
	margin: 15px 0;
	align-items: center;
	justify-content: left;
}

.navbar ul {
	padding: 0;
	margin: 0;
}

.navbar {
	padding: 0 25px;
	text-align: right;
	background-color: #71296f;
	box-shadow: 0 4px 20px -10px #cdcdcd;
}

.navbar ul li {
	list-style-type: none;
	display: inline-block;
	margin-left: 30px;
}

.navbar ul li a {
	font-size: 16px;
	padding: 20px 0;
	color: #000000;
	font-weight: 500;
	display: inline-block;
	text-decoration: none;
	transition: all 0.4s ease;
	border-bottom: 2px solid transparent;
}

.navbar ul li a.active {
	color: #debd5f;
	border-bottom: 2px solid #debd5f;
}

.navbar ul li:first-child {
	margin-left: 5px;
}